import { Component, OnInit } from '@angular/core';
import { MenuItem } from '../../shared/interfaces';
import { MENU_ITEMS } from '../../shared/constants';
import { RolesService, UserService } from '../../modules/auth/services';
import { MsalService } from '@azure/msal-angular';
import { ConfigurationService, TenantService } from '../../services';
import { catchError, Observable, of, tap } from 'rxjs';
import { Tenant } from '../../modules/shared/interfaces';
import { MaintenanceService } from '../../modules/shared/services';
import { environment } from '../../../environments/environment';
import { HealthCheckResponsePayload } from '../../shared/interfaces/responses';

@Component({
  templateUrl: './side-navbar-route.layout.component.html',
  styleUrls: ['./side-navbar-route.layout.component.scss'],
  standalone: false,
})
export class SideNavbarRouteLayoutComponent implements OnInit {
  public tenantList: Observable<Tenant[]>;
  public selectedTenant: Observable<Tenant>;
  isExpanded = true;
  menuItems = this.filterMenuItems(MENU_ITEMS);
  activeMenuItem!: MenuItem;
  userDisplayName = this.userService.getActiveAccount()?.name || '<Name is not set>';
  public frontendEnvironment = environment.environment;
  public frontendVersion = environment.frontendVersion;
  public backendVersion!: string;

  /** Checks if menu panels are expanded or not. */
  public expandedMenuPanels: boolean[] = [];

  constructor(
    private readonly rolesService: RolesService,
    private readonly msalService: MsalService,
    private readonly userService: UserService,
    private readonly tenantService: TenantService,
    private readonly configurationService: ConfigurationService,
    private readonly maintenanceService: MaintenanceService
  ) {
    this.tenantList = this.tenantService.tenantList;
    this.selectedTenant = this.tenantService.selectedTenant;
    /** Trigger the timer via injecting the service. At this level it's only
     * available to logged-in users. */
    void maintenanceService;
  }

  public ngOnInit() {
    this.activeMenuItem = this.menuItems[0];

    for (let i = 0; i < this.menuItems.length; i++) {
      const key = `menuPoint${i}`;
      const storedValue = localStorage.getItem(key);
      this.expandedMenuPanels[i] = storedValue ? storedValue === 'true' : false;
    }

    this.configurationService
      .getHealthCheck()
      .pipe(
        catchError(() => {
          return of();
        }),
        tap((healthCheck: HealthCheckResponsePayload) => {
          this.backendVersion = healthCheck.version;
        })
      )
      .subscribe();
  }

  /**
   * Changes the selected tenant's value and the value of the local storage.
   *
   * @param tenant The selected tenant from the selector.
   */
  public changeTenant(tenant: Tenant): void {
    this.tenantService.selectedTenant.next(tenant);
  }

  async signOut() {
    await this.msalService.instance.handleRedirectPromise();
    void this.msalService.logoutRedirect({
      postLogoutRedirectUri: '/auth/sign-out-success',
    });
  }

  private filterMenuItems(routes: MenuItem[]): MenuItem[] {
    const filteredRoutes = routes.map(route => {
      let newRoute: MenuItem | undefined;
      const groupAllowed = this.rolesService.applyRoleCondition(route.role);
      if (groupAllowed) {
        if (route.children) {
          const newChildren: MenuItem[] = [];
          for (const child of route.children) {
            if (this.rolesService.applyRoleCondition(child.role)) {
              newChildren.push(child);
            }
          }
          if (newChildren.length > 0) {
            newRoute = {
              ...route,
              children: newChildren,
            };
          }
        } else {
          newRoute = route;
        }
      }
      return newRoute;
    });

    return filteredRoutes.filter(route => route) as MenuItem[];
  }

  /**
   * Changes the expanded value of a selected menu point.
   *
   * @param index The selected menu header index.
   */
  public toggleMenuPanel(index: number): void {
    this.expandedMenuPanels[index] = !this.expandedMenuPanels[index];
    localStorage.setItem(`menuPoint${index}`, String(this.expandedMenuPanels[index]));
  }
}
