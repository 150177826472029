import { Component, OnInit, ViewChild } from '@angular/core';
import { BannerContainerComponent } from '../../modules/shared/components';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
  standalone: false,
})
export class PageNotFoundComponent implements OnInit {
  @ViewChild(BannerContainerComponent, { static: true })
  public bannerContainer!: BannerContainerComponent;

  ngOnInit(): void {
    this.bannerContainer.showError(`The requested page doesn't exist, please check the URL in the address bar.`, {
      closable: false,
    });
  }
}
